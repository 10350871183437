import React from 'react'

function PixExplorer() {
    return (
        <>
            <div className='h-screen w-screen'>
                <h1 className='text-center text-7xl mt-40 font-bold'>COMING SOON</h1>
            </div>
        </>
    )
}

export default PixExplorer