import React, { useState } from "react";
import cn from "classnames";
import styles from "./PutSale.module.sass";
import Icon from "../../../../components/Icon";
import Switch from "../../../../components/Switch";
import Loader from "../../../../components/Loader";

const items = [
  {
    title: "Enter your price",
    value: "ETH",
  },
  {
    title: "Service fee",
    value: "1.5%",
  },
  {
    title: "Total amount",
    value: "0 ETH",
  },
];

const PutSale = ({ className,cancleAndSale,setVisibleModalSale,cancelandSaleDone,setcancelAndSaleDone }) => {
  const [price, setPrice] = useState(false);

  return (
    <div className={cn(className, styles.sale)}>
      <div className={cn("h4", styles.title)}>Put on sale</div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <Icon name="coin" size="24" />
        </div>
        <div className={styles.details}>
          <div className={styles.info}>Instant sale price</div>
          <div className={styles.text}>
            Enter the price for which the item will be instanly sold
          </div>
        </div>
        {/* <Switch className={styles.switch} value={price} setValue={setPrice} /> */}
      </div>
      <div className={styles.table}>
        {items.map((x, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.col}>{x.title}</div>
            <div className={styles.col}>{x.value}</div>
          </div>
        ))}
      </div>
      <div className={styles.btns}>
        <button 
        onClick={cancleAndSale}
        className={cn(`${cancelandSaleDone ? "button loading" : "button"}` , styles.button)}>{
          cancelandSaleDone? <Loader></Loader> : "Continue"
        }</button>
        <button 
        onClick={()=>{setVisibleModalSale(false)}}
        className={cn("button-stroke", styles.button)}>Cancel</button>
      </div>
    </div>
  );
};

export default PutSale;
